import React, { useRef } from "react";
import { recipes as data } from "../data/recipes";
import "./recipe.scss";
import Layout from "../components/Layout";
import { Button, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";

const Recipe = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;
  const componentRef = useRef();

  const printRecipe = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "recipe",
    copyStyles: true,
  });

  return (
    <Layout>
      <div ref={componentRef}>
        <div
          className={
            data[i].featured ? "featured-recipe-header" : "recipe-header"
          }
        >
          <div
            style={{
              backgroundImage: `url(${data[i].recipeImage})`,
            }}
            className={
              data[i].featured
                ? "featured-recipe-header--recipe-image"
                : "recipe-header--recipe-image"
            }
          ></div>
          <div
            className={
              data[i].featured
                ? "featured-recipe-header--recipe-description"
                : null
            }
          >
            <div
              className={
                data[i].featured
                  ? "featured-recipe-header--recipe-description--recipe-title"
                  : "recipe-header--recipe-title"
              }
            >
              <img
                src={data[i].recipeTitleImage}
                alt={data[i].recipeTitleImageAlt}
              />
            </div>
            <p>{data[i].description}</p>
          </div>
          {data[i].featured ? (
            <img
              className="featured-recipe-header--recipe-featured-image"
              loading="lazy"
              src={data[i].featuredImage}
              alt="Featured Recipe"
            />
          ) : null}
        </div>
        <div
          className={
            data[i].featured ? "recipe-hero" : "recipe-hero--display-none"
          }
        >
          <img
            loading="lazy"
            src={data[i].recipeImage}
            alt={data[i].recipeImageAlt}
          />
        </div>
        <div className="recipe-content">
          <Row md={2} xs={1} className="row">
            <div className="recipe-content--recipe-column">
              <h2>What you Need</h2>
              <dl>
                {data[i].recipeIngredients.map((item, i) => (
                  <>
                    <dt>{item.quantity}</dt>
                    <dd>{item.ingredient}</dd>
                  </>
                ))}
              </dl>
            </div>
            <div className="recipe-content--recipe-column">
              <h2>How To Make It</h2>
              <dl>
                {data[i].recipeSteps.map((item, i) => (
                  <>
                    <dt>{item.step}</dt>
                    <dd>{item.instruction}</dd>
                  </>
                ))}
              </dl>
              <Button
                variant="outline-danger"
                className="recipe-content--recipe-column--button"
                onClick={printRecipe}
              >
                Print this Recipe
              </Button>
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default Recipe;
